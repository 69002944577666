import { FETCHING_USER, RECEIVE_USER } from '../actions'

const initialState = {}

export default (state = initialState, action) => {
  const newState = { ...state }
  switch (action.type) {
    case FETCHING_USER:
      newState['fetching'] = true
      return newState
    case RECEIVE_USER:
      newState['fetching'] = false
      newState['user'] = action.user
      return newState
    default:
      return state
  }
}