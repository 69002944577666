import React from 'react'
import { createStore as reduxCreateStore, applyMiddleware, compose } from 'redux'
import { createDevTools } from 'redux-devtools'
import DockMonitor from 'redux-devtools-dock-monitor'
import LogMonitor from 'redux-devtools-log-monitor'
import logger from 'redux-logger'
import thunkMiddleware from 'redux-thunk'
import reducer from './'

export const DevTools = createDevTools(
  <DockMonitor
    defaultIsVisible={false}
    changePositionKey="ctrl-q"
    toggleVisibilityKey="ctrl-h"
  >
    <LogMonitor />
  </DockMonitor>
)

const devTools = process.env.NODE_ENV !== 'production'
  ? typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION__
    ? typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION__()
    : DevTools.instrument()
  : noop => noop

const reduxLoggerMiddleware =
  process.env.DEBUG === 'true' ? logger : noop => noop => noop

const middleware = applyMiddleware(
  thunkMiddleware,
  // Make sure redux logger is last in the middleware chain
  // https://github.com/evgenyrodionov/redux-logger/issues/20
  reduxLoggerMiddleware,
)

const enhancer = compose(
  middleware,
  devTools,
)


const createStore = () => reduxCreateStore(reducer, enhancer)
export default createStore