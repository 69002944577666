import React from 'react'
import { Provider } from 'react-redux'

import createStore, { DevTools } from './src/state/createStore'

// eslint-disable-next-line react/display-name,react/prop-types
export default ({ element }) => {
  // Instantiating store in `wrapRootElement` handler ensures:
  //  - there is fresh store for each SSR page
  //  - it will be called only once in browser, when React mounts
  const store = createStore()

  if (process.env.NODE_ENV === 'production') {
    return <Provider store={store}><>{element}</></Provider>
  } else {
    return <Provider store={store}><>{element}<DevTools /></></Provider>
  }
}